<script>
import {Chart} from '@antv/g2';
import constants from '@/components/Constants.vue'

export default {
  name: "LogNginxChart",
  data() {
    return {
      chart: null,
      loading: false,
      model_list: [],
      begin_time: "",
      end_time: "",
      timestampDay: 24 * 60 * 60,
      chartTypeList: constants.ChartTypeList,
      chartType: constants.ChartTypeList[0].name,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date().getTime();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }, {
          text: '一月前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 31);
            picker.$emit('pick', date);
          }
        }]
      },
    };
  },
  mounted() {
    let _me = this;
    let t = new Date().getTime();
    _me.begin_time = _me.$common.getDatetimeFromTime(t - (14 * _me.timestampDay * 1000), 'begin');
    _me.end_time = _me.$common.getDatetimeFromTime(t, 'end');
    _me.getModelList();
  },
  destroyed() {
    this.chart.destroy();
  },
  methods: {
    getModelList() {
      let _me = this;
      if (!_me.validDate()) {
        return
      }

      let params = {
        begin_time: _me.$common.formatTimestampLongZone(_me.begin_time, 0),
        end_time: _me.$common.formatTimestampLongZone(_me.end_time, 0),
      };
      _me.loading = true;
      _me.$common
          .httpPost("/api/log/admin/nginx/find/valid/chart", params)
          .then((res) => {
            _me.loading = false;
            if (res.code === "1000000" && res.data) {
              _me.model_list = res.data;
              _me.createChart();
            } else {
              _me.$message({
                message: res.message || "获取数据失败",
                type: "error",
              });
            }
          })
          .catch((err) => {
            _me.loading = false;
            _me.$message({
              message: err.message || "获取数据失败",
              type: "error",
            });
          });
    },
    createChart() {
      let _me = this;

// 初始化图表实例
      if (!_me.chart) {
        _me.chart = new Chart({
          container: 'container',
          theme: 'classic',
          autoFit: true,
          marginLeft: 20,
          marginRight: 20,
          marginBottom: 60
        });
      } else {
        _me.chart.clear();
      }

      let data = _me.model_list;
      if (_me.chartType === "多度量条形图") {
        _me.createGroupBarMultiMeasure(data);
      } else if (_me.chartType === "系列颜色编码折线图") {
        _me.createLineSeriesVarColor(data);
      } else if (_me.chartType === "聚合折线图") {
        _me.createLineAggregatedLabel(data);
      }
    },
    createGroupBarMultiMeasure() {
      let _me = this;

      _me.chart
          .interval()
          .data(_me.model_list)
          .encode('x', 'date')
          .encode('y', 'view')
          .encode('series', 'strategy')
          .encode('color', 'strategy')
          .scale('y', {nice: true})
          .axis('x', {labelTransform: 'rotate(90)'})
          .scale('color', {palette: 'accent'})
          .label({
            text: 'view',
            transform: [{type: 'overlapDodgeY'}],
            fontSize: 12,
          })
          .animate('enter', {type: 'growInY'})
          .interaction('elementHighlightByColor', true)
          .state('inactive', {opacity: 0.5})

      _me.chart.render();
    },
    createLineSeriesVarColor(data) {
      let _me = this;
      _me.chart
          .line()
          .data(data)
          .encode('x', 'date')
          .encode('y', 'view')
          .encode('series', 'strategy')
          .encode('color', 'view')
          .style('gradient', 'y')
          .style('shape', 'smooth')
          .style('strokeWidth', 6)
          .scale('y', {nice: true})
          .axis('x', {labelTransform: 'rotate(90)'})
          .label({
            text: 'view',
            transform: [{type: 'overlapDodgeY'}],
            fontSize: 12,
          })
          .animate('enter', {type: 'growInX'})

      _me.chart.render();
    },
    createLineAggregatedLabel(data) {
      let _me = this;

      _me.chart
          .line()
          .data(data)
          .encode('x', 'date')
          .encode('y', 'view')
          .encode('color', 'strategy')
          .scale('y', {nice: true})
          .axis('x', {labelTransform: 'rotate(90)'})
          .scale('color', {palette: 'tableau10'})
          .label({
            text: 'view',
            transform: [{type: 'overlapDodgeY'}],
            fontSize: 12,
          })
          .animate('enter', {type: 'growInX'})

      _me.chart.render();
    },
    validDate() {
      let _me = this;

      if (!_me.begin_time || _me.begin_time === "") {
        _me.$message({
          message: "开始时间不正确",
          type: "error",
        });
        return false;
      }

      if (!_me.end_time || _me.end_time === "") {
        _me.$message({
          message: "结束时间不正确",
          type: "error",
        });
        return false;
      }

      let days = new Date(_me.end_time).getTime() - new Date(_me.begin_time).getTime()

      if (days < 0) {
        _me.$message({
          message: "开始时间不能大于结束时间",
          type: "error",
        });
        return false;
      }

      return true
    }
  },
}
</script>

<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item>
              <el-radio-group v-model="chartType" @change="createChart()">
                <el-radio-button v-for="item in chartTypeList" :label="item.name" :key="item.alias"
                                 :value="item.alias"></el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="开始日期:">
              <el-date-picker
                  v-model="begin_time"
                  type="date"
                  placeholder="选择开始日期"
                  align="center"
                  :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="结束日期:">
              <el-date-picker
                  v-model="end_time"
                  type="date"
                  placeholder="选择结束日期"
                  align="center"
                  :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
            <el-button
                class="top-button"
                size="medium"
                type="primary"
                round
                @click="getModelList"
            >过滤
            </el-button>
          </el-form>
        </div>
      </el-header>
      <div class="container" id="container"></div>
    </el-container>
  </div>
</template>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>