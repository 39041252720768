<script>
export default {
  name: "LogNginxData",
  data() {
    return {
      loading: false,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      model_list: [],
      begin_time: "",
      end_time: "",
      timestampDay: 24 * 60 * 60,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date().getTime();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }, {
          text: '一月前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 31);
            picker.$emit('pick', date);
          }
        }]
      },
    };
  },
  mounted() {
    let _me = this;
    let t = new Date().getTime();
    _me.begin_time = _me.$common.getDatetimeFromTime(
        t - 14 * _me.timestampDay * 1000,
        "begin"
    );
    _me.end_time = _me.$common.getDatetimeFromTime(t, "end");
    _me.getModelList();
  },
  methods: {
    getModelList() {
      let _me = this;

      if (!_me.validDate()) {
        return;
      }

      let params = {
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "-date",
        begin_time: _me.$common.formatTimestampLongZone(_me.begin_time, 0),
        end_time: _me.$common.formatTimestampLongZone(_me.end_time, 0)
      };
      _me.loading = true;
      _me.$common
          .httpPost("/api/log/admin/nginx/find/valid/list", params)
          .then(res => {
            _me.loading = false;
            if (res.code === "1000000") {
              _me.model_list = res.data && res.data.array ? res.data.array : [];
              _me.page_total = res.data.total
                  ? res.data.total
                  : _me.model_list.length;
            } else {
              _me.$message({
                message: res.message || "获取数据失败",
                type: "error"
              });
            }
          })
          .catch(err => {
            _me.loading = false;
            _me.$message({
              message: err.message || "获取数据失败",
              type: "error"
            });
          });
    },
    handleCurrentChange(number) {
      this.page_number = number;
      this.getModelList();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getModelList();
    },
    downloadExcel() {
      let _me = this;

      if (!_me.validDate()) {
        return
      }

      let params = {
        begin_time: _me.$common.formatTimestampLongZone(_me.begin_time, 0),
        end_time: _me.$common.formatTimestampLongZone(_me.end_time, 0),
      };


      _me.$confirm("下载有效数据 " + new Date(_me.begin_time).Format("yyyy-MM-dd", false, false) + " ~ " + new Date(_me.end_time).Format("yyyy-MM-dd", false, false), "提示", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        beforeClose: async (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            instance.closeOnClickModal = false;
            instance.closeOnPressEscape = false;
            instance.showCancelButton = false;
            instance.showClose = false;

            let filenameRegex = /attachment; filename\*=utf-8''(\S*\.xlsx)/;
            await _me.$common
                .httpPost("/api/log/admin/nginx/download/valid/data", params, {
                  responseType: "arraybuffer",
                  isAllData: true
                })
                .then(res => {
                  _me.$common.downloadFile(
                      res,
                      res.headers["content-type"],
                      filenameRegex
                  );
                })
                .catch(err => {
                  _me.$message({
                    message: err.message || "获取数据失败",
                    type: "error"
                  });
                })
                .finally(() => {
                  done();
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "确定";
                });
          } else {
            done();
          }
        }
      });
    },
    validDate() {
      let _me = this;

      if (!_me.begin_time || _me.begin_time === "") {
        _me.$message({
          message: "开始时间不正确",
          type: "error"
        });
        return false;
      }

      if (!_me.end_time || _me.end_time === "") {
        _me.$message({
          message: "结束时间不正确",
          type: "error"
        });
        return false;
      }

      let days =
          new Date(_me.end_time).getTime() - new Date(_me.begin_time).getTime();

      if (days < 0) {
        _me.$message({
          message: "开始时间不能大于结束时间",
          type: "error"
        });
        return false;
      }

      return true;
    },
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计值\n平均值';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += '';
        } else {
          sums[index] = '';
        }
      });

      sums.forEach((k, v) => {
        if (v > 1 && k !== "") {
          sums[v] = `SUM: ${k} / AVG: ${(Number(k) / data.length).toFixed(2)}`;
        }
      })

      return sums;
    }
  }
};
</script>

<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="开始日期:">
              <el-date-picker
                  v-model="begin_time"
                  type="date"
                  placeholder="选择开始日期"
                  align="center"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="结束日期:">
              <el-date-picker
                  v-model="end_time"
                  type="date"
                  placeholder="选择结束日期"
                  align="center"
                  :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
            <el-button
                class="top-button"
                size="medium"
                type="primary"
                round
                @click="getModelList"
            >过滤
            </el-button>
            <el-button
                class="top-button"
                size="medium"
                type="primary"
                round
                @click="downloadExcel"
            >下载
            </el-button>
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
            :data="model_list"
            style="width: 100%"
            :cell-style="$common.tableStyle"
            :header-cell-style="$common.tableStyle"
            border
            default-expand-all
            show-summary
            :summary-method="getSummaries"
        >
          <el-table-column label="#" width="80" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="记录时间" align="center">
            <template #default="scope">
              <p
                  style="font-weight: bold"
              >{{ new Date(scope.row.date).Format("yyyy-MM-dd", false, false) }}</p>
            </template>
          </el-table-column>
          <el-table-column label="页面浏览量" align="center" prop="pv"></el-table-column>
          <el-table-column label="独立访客数" align="center" prop="uv"></el-table-column>
          <el-table-column label="访客访问数" align="center" prop="vv"></el-table-column>
          <el-table-column label="独立IP数" align="center" prop="ip"></el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
            style="margin-top: 15px"
            background
            layout="sizes, prev, pager, next, jumper"
            :page-sizes="[20, 30, 50, 100, 300, 500]"
            :page-size="page_size"
            @size-change="handleSizeChange"
            :pager-count="9"
            :total="page_total"
            :current-page="page_number"
            @current-change="handleCurrentChange"
        ></el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<style scoped>
</style>