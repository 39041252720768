<script>
const LogStrategyTypeList = [
  {
    name: "页面浏览量",
    strategy: "pv"
  },
  {
    name: "独立访客数",
    strategy: "uv"
  },
  {
    name: "访客访问数",
    strategy: "vv"
  },
  {
    name: "独立IP数",
    strategy: "ip"
  }
];

const ChartTypeList = [
  {
    name: "多度量条形图",
    type: "group",
    alias: "bar-multi-measure"
  },
  {
    name: "系列颜色编码折线图",
    type: "line",
    alias: "line-series-var-color"
  },
  {
    name: "聚合折线图",
    type: "line",
    alias: "line-aggregated-label"
  },
]
export default {
  LogStrategyTypeList: LogStrategyTypeList,
  ChartTypeList: ChartTypeList
}
</script>