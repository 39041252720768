<script>
export default {
  name: "LogNginxList",
  data() {
    return {
      loading: false,
      ip: "",
      path: "",
      country: "",
      province: "",
      page_total: 0,
      page_size: 20,
      page_number: 1,
      model_list: [],
      begin_time: "",
      end_time: "",
      timestampDay: 24 * 60 * 60
    };
  },
  mounted() {
    let _me = this;
    let t = new Date().getTime();
    _me.begin_time = _me.$common.getDatetimeFromTime(
      t - 14 * _me.timestampDay * 1000,
      "begin"
    );
    _me.end_time = _me.$common.getDatetimeFromTime(t, "end");
    _me.getModelList();
  },
  methods: {
    getModelList() {
      let _me = this;

      if (!_me.validDate()) {
        return;
      }

      let params = {
        ip: _me.ip,
        path: _me.path,
        country: _me.country,
        province: _me.province,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "",
        begin_time: _me.$common.formatTimestampLongZone(_me.begin_time, 0),
        end_time: _me.$common.formatTimestampLongZone(_me.end_time, 0)
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/log/admin/nginx/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.model_list = res.data && res.data.array ? res.data.array : [];
            _me.page_total = res.data.total
              ? res.data.total
              : _me.model_list.length;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error"
          });
        });
    },
    handleCurrentChange(number) {
      this.page_number = number;
      this.getModelList();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getModelList();
    },
    validDate() {
      let _me = this;

      if (!_me.begin_time || _me.begin_time === "") {
        _me.$message({
          message: "开始时间不正确",
          type: "error"
        });
        return false;
      }

      if (!_me.end_time || _me.end_time === "") {
        _me.$message({
          message: "结束时间不正确",
          type: "error"
        });
        return false;
      }

      let days =
        new Date(_me.end_time).getTime() - new Date(_me.begin_time).getTime();

      if (days < 0) {
        _me.$message({
          message: "开始时间不能大于结束时间",
          type: "error"
        });
        return false;
      }

      return true;
    }
  }
};
</script>

<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="开始时间:">
              <el-date-picker
                v-model="begin_time"
                type="datetime"
                placeholder="选择开始时间"
                default-time="00:00:00"
                align="center"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:">
              <el-date-picker
                v-model="end_time"
                type="datetime"
                placeholder="选择结束时间"
                default-time="23:59:59"
                align="center"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="来源IP:">
              <el-input v-model="ip" clearable size="medium" placeholder="请输入来源IP"></el-input>
            </el-form-item>
            <el-form-item label="访问路径:">
              <el-input v-model="path" clearable size="medium" placeholder="请输入访问路径"></el-input>
            </el-form-item>
            <el-form-item label="来源国家:">
              <el-input v-model="country" clearable size="medium" placeholder="请输入来源国家"></el-input>
            </el-form-item>
            <el-form-item label="来源省份:">
              <el-input v-model="province" clearable size="medium" placeholder="请输入来源省份"></el-input>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getModelList"
            >过滤</el-button>
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="model_list"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" width="80" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="记录时间" align="center">
            <template #default="scope">
              <p
                style="font-weight: bold"
              >{{ new Date(scope.row.created_at).Format("yyyy-MM-dd hh:mm:ss", false, true) }}</p>
            </template>
          </el-table-column>
          <el-table-column label="来源IP" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.remote_addr }}</p>
            </template>
          </el-table-column>
          <el-table-column label="来源国家" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.country }}</p>
            </template>
          </el-table-column>
          <el-table-column label="来源省份" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.province }}</p>
            </template>
          </el-table-column>
          <el-table-column label="访问路径" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.path }}</p>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px"
          background
          layout="sizes, prev, pager, next, jumper"
          :page-sizes="[20, 30, 50, 100]"
          :page-size="page_size"
          @size-change="handleSizeChange"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<style scoped>
</style>